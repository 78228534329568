import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import ShieldIcon from "../../images/Card-Shield-small-icon.svg"
import BriefcaseIcon from "../../images/Card-Briefcase-small-icon.svg"
import MoneyIcon from "../../images/Card-Money-small-icon.svg"
import PortalBig from "../../images/Card-Portal-Big.png"

export const Boundaries = () => {
  const data = [
    {
      title: "Maximum security with no compromises",
      desc: "Save, hold, and spend with confidence.",
      icon: ShieldIcon,
    },
    {
      title: "Made for your",
      title2: "business",
      desc:
        "Process your vendor payments locally and internationally, buying inventories. Payments for recurring SaaS subscriptions, and online billing.",
      icon: BriefcaseIcon,
    },
    {
      title: "Always ready to",
      title2: "launch",
      desc: "One account manager per business, ready at your service 24/7.",
      icon: MoneyIcon,
    },
  ]
  return (
    <MainDiv>
      <Container className="text-center">
        <MainTitle>
          Breaking
          <br />
          Banking Boundaries
          <br />
          <span style={{ color: "#F5C24F" }}>----</span>
        </MainTitle>
        <Row className="mt-3 mb-3">
          {data.map(({ title, title2, desc, icon }, index) => {
            return (
              <Col lg={4} key={index}>
                <CardDiv>
                  <CardTitle>
                    {title}
                    <br />
                    {title2}
                  </CardTitle>
                  <CardSubTitle>{desc}</CardSubTitle>
                  <CardImage src={icon} />
                </CardDiv>
              </Col>
            )
          })}
        </Row>
        <img src={PortalBig} />
      </Container>
    </MainDiv>
  )
}

const MainDiv = styled.div`
  background-color: #102c57;
  min-height: 500px;
  margin-top: -10px;
`
const MainTitle = styled.h2`
  font-family: "Halenoir-Black";
  font-size: 60px;
  color: white;
`

const CardDiv = styled.div`
  padding: 25px;
  margin: 0px 5px 10px 5px;
  background-color: white;
  border-radius: 10px;
  height: 220px;
  text-align: left;
`

const CardTitle = styled.h3`
  font-family: "Halenoir-Black";
  color: #da649f;
  font-size: 24px;
  height: 60px;
`
const CardSubTitle = styled.h3`
  font-family: "Halenoir-Medium";
  color: #828282;
  font-size: 16px;
`
const CardImage = styled.img`
  position: absolute;
  bottom: 20px;
  right: 35px;
`
