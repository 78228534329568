import React from 'react'
import { Boundaries } from '../components/Card/Boundaries'
import { Header } from '../components/Card/Header'
import { Introduction } from '../components/Card/Introduction'
import { NavigationBar } from '../components/Card/Navbar'
import { Power } from '../components/Card/Power'
import { Pricing } from '../components/Card/Pricing'
import { Register } from '../components/Card/Register'

function Card() {
    return (
        <div>
            <NavigationBar />
            <Header />
            <Introduction />
            <Power />
            <Boundaries />
            <Pricing />
            <Register />
        </div>
    )
}

export default Card
