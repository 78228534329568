import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import CardGlobe from "../../images/Card-globe-white.png"
import BigCard from "../../images/Card-card-shadow.png"
import EasyMoneyIcon from "../../images/Card-Easy-small-icon.png"
import SpendMoneyIcon from "../../images/Card-Spend-small-icon.png"
import CashbackIcon from "../../images/Card-Cashback-small-icon.png"
import SendIcon from "../../images/Card-Send-small-icon.png"
import SaveIcon from "../../images/Card-Save-small-icon.png"
import CrossIcon from "../../images/Card-Cross-small-icon.png"

export const Introduction = () => {
  const FirstCardData = [
    {
      title: "Easy money management",
      desc:
        "Issue unlimited virtual and physical cards and have flexibility to set the limit for your team members. Perfect for SaaS, digital ads and any recurring payments.",
      icon: EasyMoneyIcon,
    },
    {
      title: "Spend like a local on debit cards with great FX rates",
      desc:
        "You can easily switch to the currency you are transacting in, spend like a local globally.",
      icon: SpendMoneyIcon,
    },
    {
      title: "Big cashback and rewards",
      desc:
        "Enjoy up to 1% cashback on online transactions and earn more rewards .",
      icon: CashbackIcon,
    },
  ]
  const SecondCardData = [
    {
      title: "Send money to every corner of the world",
      desc: "We covered any countries and currencies of your choice",
      icon: SendIcon,
    },
    {
      title: "Save more, worry less",
      desc: "Enjoy flat fee, competitive rates, real time, and no hidden cost.",
      icon: SaveIcon,
    },
    {
      title: "Single, bulk, and API payment",
      desc:
        "Whether it’s one or thousands of transactions, we got you all covered.",
      icon: CrossIcon,
    },
  ]
  return (
    <MainDiv>
      <SeparateDiv />
      <Container>
        <div style={{ marginTop: -270 }}>
          <BoxDiv>
            <MainTitle>Smart Corporate Cards</MainTitle>
            <MainTitle fsize="22px" ffamily="Halenoir-Medium">
              <SpanGreen>Get ready</SpanGreen> to scale your business.{" "}
              <SpanGreen>Set</SpanGreen> your budget. <SpanGreen>Go</SpanGreen>{" "}
              spend.
            </MainTitle>
            <Row>
              <Col lg={7}>
                <div className="mt-5">
                  {FirstCardData.map(({ title, desc, icon }, index) => {
                    return (
                      <RowDiv key={index}>
                        <RowCol md={2} className="px-0">
                          <img src={icon} className="mt-1" />
                        </RowCol>
                        <RowCol md={10} className="px-0">
                          <TitleCard>{title}</TitleCard>
                          <DescCard>{desc}</DescCard>
                        </RowCol>
                      </RowDiv>
                    )
                  })}
                </div>
              </Col>
              <Col lg={5}>
                <CardImage src={BigCard} />
              </Col>
            </Row>
          </BoxDiv>
          <BoxDiv className="mt-4">
            <MainTitle>Local and Cross-Border Money Transfer</MainTitle>
            <MainTitle fsize="22px" ffamily="Halenoir-Medium">
              Simpler and better transfer service to streamline your
              transaction.
            </MainTitle>
            <Row>
              <Col lg={7}>
                <div className="mt-5">
                  {SecondCardData.map(({ title, desc, icon }, index) => {
                    return (
                      <RowDiv key={index}>
                        <RowCol md={2} className="px-0">
                          <img src={icon} className="mt-1" />
                        </RowCol>
                        <RowCol md={10} className="px-0">
                          <TitleCard>{title}</TitleCard>
                          <DescCard>{desc}</DescCard>
                        </RowCol>
                      </RowDiv>
                    )
                  })}
                </div>
              </Col>
              <Col lg={5}>
                <GlobeImage src={CardGlobe} />
              </Col>
            </Row>
          </BoxDiv>
        </div>
      </Container>
    </MainDiv>
  )
}

const MainDiv = styled.div`
  background-color: #102c57;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
`

const SeparateDiv = styled.div`
  height: 150px;
  background-color: rgb(230, 171, 201);
  background: linear-gradient(
    180deg,
    rgba(230, 171, 201, 1) 0%,
    rgba(216, 232, 236, 0) 50%
  );
`

const BoxDiv = styled.div`
  /* margin-top: -120px; */
  padding: 30px 50px;
  border-radius: 10px;
  /* height: 650px; */
  width: 100%;
  background: rgb(243, 219, 230);
  background: linear-gradient(
    90deg,
    rgba(243, 219, 230, 1) 0%,
    rgba(216, 232, 236, 1) 100%
  );
  overflow: hidden;
`

const RowDiv = styled.div`
  display: flex;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const RowCol = styled(Col)`
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 12px;
  }
`

const MainTitle = styled.h2`
  font-family: ${p => p.ffamily || "Halenoir-Black"};
  font-size: ${p => p.fsize || "40px"};
  color: #102c57;
  @media (max-width: 768px) {
    text-align: center;
  }
`

const SpanGreen = styled.span`
  color: #54b0bd;
`
const GlobeImage = styled.img`
  position: absolute;
  bottom: -30px;
  right: -35px;
  min-width: 500px;
  @media (max-width: 992px) {
    display: none;
  }
`

const CardImage = styled.img`
  position: absolute;
  bottom: 0px;
  left: -100px;
  min-width: 800px;
  @media (max-width: 992px) {
    display: none;
  }
`

const TitleCard = styled.h4`
  font-size: 22px;
  font-family: "Halenoir-Black";
  color: #da649f;
`
const DescCard = styled.h5`
  font-size: 16px;
  font-family: "Halenoir-Medium";
  color: #102c57;
`
