import React from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"
import { CustomButton } from "./Button"
import HeaderImg from "../../images/header-card.png"

export const Header = () => {
  return (
    <>
      <MainDiv>
        <Container style={{ textAlign: "center" }}>
          <FontTitle>
            Global Banking
            <br />
            Built for Startups and SMEs
            <br />
            ----
          </FontTitle>
          <FontDesc>
            Level up your business with smart corporate cards and seamless
            global payment
          </FontDesc>
          <div className="d-flex justify-content-center mt-4">
            <CustomButton title="Join now!" isNavy style={{ marginRight: 5 }} />
            <CustomButton title="Book a demo" />
          </div>
          <MainImage src={HeaderImg} />
        </Container>
      </MainDiv>
    </>
  )
}

const MainDiv = styled.div`
  /* margin-top: 115px; */
  background-color: rgb(255, 255, 255);
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 1) 0%,
    rgba(218, 100, 159, 0.5) 55%
  );
  background-position: 0% 200px;
  padding-top: 220px;
  height: 1400px;
  @media (max-width: 768px) {
    background: radial-gradient(
      to bottom right,
      rgba(255, 255, 255, 1) 0%,
      rgba(218, 100, 159, 0.5) 35%
    );
    background-position: 0% 200px;
  }
`

const FontTitle = styled.h2`
  text-align: center;
  font-family: "Halenoir-Black";
  font-size: 65px;
  color: #da649f;
`

const FontDesc = styled.h3`
  color: #102c57;
  text-align: center;
  font-family: "Halenoir-Medium";
  font-size: 22px;
`

const MainImage = styled.img`
  @media (max-width: 768px){
    margin-top: 50px;
  }
`