import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { CustomButton } from "./Button"

export const Power = () => {
  return (
    <MainDiv>
      <MainContainer>
        <MainTitle>Power your business today</MainTitle>
        <div className="d-flex justify-content-center mt-4">
          <CustomButton
            title="Join the waitlist!"
            isYellow
            style={{ marginRight: 5, minWidth: 160 }}
          />
          <CustomButton title="Talk to our Expert" style={{ minWidth: 160 }} />
        </div>
      </MainContainer>
    </MainDiv>
  )
}

const MainDiv = styled.div`
  background-color: #102c57;
  min-height: 700px;
  margin-top: -10px;
`

const MainTitle = styled.h2`
  font-family: "Halenoir-Black";
  font-size: 60px;
  color: #da649f;
`

const MainContainer = styled(Container)`
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
  justify-content: center;
`
