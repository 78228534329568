import React from "react"
import { Container, Navbar } from "react-bootstrap"
import styled from "styled-components"
import NewLogo from "../../images/new-logo-transfez.png"
import "./card.css"

export const NavigationBar = () => {
  return (
    <div>
      <div className="navbarTopNew">
        <Container>
          <LogoImg src={NewLogo} alt="logo" />
        </Container>
      </div>
      <NavbarSubDiv>
        <FontSub>
          Join the waitlist now to enjoy 12-months free subscription. xxx seats
          left. Claim yours now!
        </FontSub>
      </NavbarSubDiv>
    </div>
  )
}

const LogoImg = styled.img`
  position: absolute;
  top: 25%;
`

const NavbarSubDiv = styled.div`
  width: 100%;
  position: fixed;
  /* height: 35px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  min-height: 35px;
  background-color: #eb6f61;
  padding: 5px 5px;
  z-index: 99;
`
const FontSub = styled.h5`
  font-family: "Halenoir-Medium";
  font-size: 16px;
  color: white;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 0px;
`
