import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"

export const Pricing = () => {
  const TopData = [
    {
      left: "Free Local Payment ",
      leftDesc: "(IDR)",
      right: "250 transactions",
    },
    {
      left: "Free International transaction",
      right: "5",
    },
    {
      left: "Special FX International Transaction*",
      right: "✓",
    },
    {
      left: "Virtual cards",
      right: "unlimited",
    },
    {
      left: "Cashback",
      right: "1%",
    },
    {
      left: "Bulk Payment ",
      leftDesc: "(Local & International)",
      right: "✓",
    },
    {
      left: "API Access",
      right: "✓",
    },
    {
      left: "Advanced Card Features",
      leftBottom: "(Setting card limit, budget, users, whitelist & blacklist)",
      right: "✓",
    },
    {
      left: "Advanced User Roles",
      right: "✓",
    },
    {
      left: "Dedicated AM",
      right: "✓",
    },
  ]
  return (
    <MainDiv>
      <Container className="my-0 py-0">
        <PricingDiv style={{}}>
          <MainTitle>Pricing</MainTitle>
          <MainSubTitle>IDR 350.000/month</MainSubTitle>
          {TopData.map((item, index) => {
            const { left, leftBottom, leftDesc, right } = item
            const isOdd = index % 2 === 0
            const isFour = index === 4
            return (
              <TableRow
                key={index}
                bcolor={!isOdd && "rgba(122, 170, 160, 0.4)"}
                mbottom={isFour && "70px"}
              >
                <div style={{ width: "60%" }}>
                  <KeyTitle ffamily="GothamBold">
                    {left}
                    {leftDesc && (
                      <span style={{ fontFamily: "GothamBook" }}>
                        {leftDesc}
                      </span>
                    )}
                    {leftBottom && (
                      <>
                        <br />
                        <span style={{ fontFamily: "GothamBook" }}>
                          {leftBottom}
                        </span>
                      </>
                    )}
                  </KeyTitle>
                </div>
                <div style={{ width: "40%" }}>
                  <KeyTitle talign="center">{right}</KeyTitle>
                </div>
              </TableRow>
            )
          })}
          <TermsText>
            *Based on countries
            <br />
            **Time needed to initiate a transfer minus verification (including
            traffic, queue, fill form, etc)
            <br />
            ***Real-time transaction in several countries
          </TermsText>
        </PricingDiv>
      </Container>
    </MainDiv>
  )
}

const MainDiv = styled.div`
  /* height: 700px; */
  background: rgb(16, 44, 87);
  background: linear-gradient(
    0deg,
    rgba(73, 171, 184, 1) 0%,
    rgba(16, 44, 87, 1) 100%
  );
`

const PricingDiv = styled.div`
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(243, 219, 230, 1) 0%,
    rgba(216, 232, 236, 1) 100%
  );
  border-radius: 10px;
  padding: 50px 100px 100px 100px;
  text-align: center;
  margin-top: -250px;
  z-index: 3;
  @media (max-width: 768px) {
    padding: 30px 20px 100px 20px;
    margin-top: -100px;
  }
`

const MainTitle = styled.h2`
  font-family: "Halenoir-Black";
  font-size: 60px;
  color: #112d58;
  margin-bottom: 50px;
`
const MainSubTitle = styled.h4`
  font-family: "GothamBold";
  font-size: 20px;
  color: #da649f;
  margin-bottom: 20px;
  text-align: right;
  margin-right: 70px;
  @media (max-width: 768px) {
    font-size: 16px;
    margin-right: 20px;
  }
`

const TableRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${p => p.bcolor || "transparent"};
  padding: 10px;
  margin-bottom: ${p => p.mbottom};
`

const KeyTitle = styled.p`
  font-family: ${p => p.ffamily || "Halenoir-Medium"};
  text-align: ${p => p.talign || "left"};
  font-size: 16px;
  color: #112d58;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
`

const TermsText = styled.div`
  margin-top: 18px;
  text-align: left;
  font-family: "GothamBook";
  font-size: 12px;
  color: #112d58;
`
