import React from "react"
import { Button } from "react-bootstrap"

export const CustomButton = ({
  title = "Next",
  onClick,
  style,
  isNavy = false,
  isYellow = false,
}) => {
  const ColorDecider = () => {
    if (isNavy) return "white"
    return "#102C57"
  }
  const BgDecider = () => {
    if (isNavy) return "#102C57"
    if (isYellow) return "#F5C24F"
    return "white"
  }

  const color = ColorDecider()
  const bgColor = BgDecider()

  return (
    <div>
      <Button
        onClick={onClick}
        style={{
          fontSize: 16,
          padding: "5px 15px",
          borderRadius: 10,
          border: "1px solid #102C57",
          fontFamily: "Halenoir-Medium",
          backgroundColor: bgColor,
          color: color,
          outline: "none",
          minWidth: 120,
          ...style,
        }}
      >
        {title}
      </Button>
    </div>
  )
}
