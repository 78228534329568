import React from "react"
import { Col, Container, Row, Form, FormControl, Button } from "react-bootstrap"
import styled from "styled-components"
import CardRegisterBig from "../../images/Card-Register-Big.png"
import "./card.css"

export const Register = () => {
  return (
    <>
      <SeparateDiv />
      <MainDiv>
        <Container>
          <MainTitle>
            Ready to start{" "}
            <span style={{ color: "white" }}>breaking banking boundaries</span>?
          </MainTitle>
          <MainSubTitle>
            Register the waitlist now to secure your seat and
            <br />
            enjoy the benefit worth of IDR 4.200.000
          </MainSubTitle>
          <MainRow className="my-5">
            <Col lg={6}>
              <div className="mt-4">
                <div className="d-flex justify-content-between mb-2">
                  <FormTitle>Free seats left</FormTitle>
                  <FormValue>3 Seats</FormValue>
                </div>
                <RowBar title="12 month" width="25%" />
                <RowBar title="6 month" />
                <RowBar title="3 month" />
                <Form>
                  <div className="d-flex">
                    <FormInput type="text" placeholder="Enter your email" />
                    <ButtonSubmit>Secure my seat</ButtonSubmit>
                  </div>
                </Form>
                <FormValue fcolor="white">
                  By registering your data you have agreed to join the waitlist
                  and get{" "}
                  <span style={{ fontFamily: "Halenoir-Black" }}>
                    12 months
                  </span>{" "}
                  free subscription
                </FormValue>
              </div>
            </Col>
            <Col lg={6}>
              <img src={CardRegisterBig} />
            </Col>
          </MainRow>
          <TermsText>Provided by Transfez © 2022</TermsText>
        </Container>
      </MainDiv>
    </>
  )
}

const RowBar = ({ title, width }) => {
  return (
    <RowBarDiv>
      <FormValue>{title}</FormValue>
      <BgProgressBar>
        <ActiveBar style={{ width: width }} />
      </BgProgressBar>
    </RowBarDiv>
  )
}

const MainDiv = styled.div`
  background-color: rgb(255, 255, 255);
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 1) 0%,
    rgba(218, 100, 159, 0.5) 55%
  );
  background-position: 300px 50px;
  padding: 100px 0px 20px 0px;
  @media (max-width: 768px){
    background-position: 0px -30px;
  }
`

const SeparateDiv = styled.div`
  position: relative;
  z-index: 5;
  margin-top: -60px;
  height: 60px;
  background: rgb(230, 171, 201);
  background: linear-gradient(
    0deg,
    rgba(230, 171, 201, 1) 0%,
    rgba(73, 171, 184, 0) 100%
  );
`

const MainRow = styled(Row)`
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

const MainTitle = styled.h2`
  text-align: center;
  font-family: "Halenoir-Black";
  font-size: 40px;
  color: #112d58;
  margin-bottom: 20px;
`

const MainSubTitle = styled.h4`
  font-family: "Halenoir-Medium";
  font-size: 20px;
  color: #112d58;
  margin-bottom: 20px;
  text-align: center;
`

const FormTitle = styled.h5`
  font-family: "Halenoir-Medium";
  font-size: 22px;
  color: white;
`
const FormValue = styled.h5`
  font-family: "Halenoir-Medium";
  font-size: 20px;
  color: ${p => p.fcolor || "#112d58"};
  margin-bottom: 0;
`

const TermsText = styled.p`
  font-size: 12px;
  color: #112d58;
  font-family: GothamBook;
  text-align: center;
`

const RowBarDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`
const BgProgressBar = styled.div`
  position: relative;
  height: 10px;
  width: 70%;
  background-color: #e5e5e5;
`

const ActiveBar = styled.div`
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ec6057;
`

const FormInput = styled(FormControl)`
  font-size: 16px !important;
  width: 100% !important;
  height: 50px;
  font-family: "Halenoir-Medium";
  color: #c4c4c4;
  padding: 20px;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
`

const ButtonSubmit = styled(Button)`
  font-family: "GothamBold" !important;
  font-size: 16px !important;
  background-color: #eb6f61;
  height: 50px;
  width: 230px;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  margin-left: -20px;
  :hover,
  :focus,
  :active,
  :visited {
    background-color: #eb6f61 !important;
  }
`
